<template>
  <v-container>
    <v-card class="mx-auto col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <v-card-title class="d-flex justify-space-around">
        <h1 class="font-weight-medium text-center">
          Reporte de Almacenamiento
        </h1>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            class="d-flex align-center text-center checkBox-origin"
            cols="12"
            sm="2"
            md="2"
          >
            <v-checkbox
              v-model="origin"
              label="Desde el origen"
              color="indigo"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col class="d-flex flex-wrap" cols="12" xs="12" sm="3" md="3">
            <span
              class="
                text-subtitle-2
                col-lg-12 col-md-12 col-sm-12 col-xs-12
                pt-0
                pb-0
              "
              :style="{ color: 'initial !important' }"
              >Rango de fechas</span
            >

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-0 pb-0">
              <v-menu
                v-model="date_range.estado"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                min-width="auto"
                :disabled="origin"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_range.value"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    hide-details
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  scrollable
                  v-model="date_range.value"
                  range
                  class="pa-0"
                  locale="es"
                >
                </v-date-picker>
              </v-menu>
            </div>
          </v-col>

          <v-col class="d-flex flex-wrap" cols="12" xs="12" sm="2" md="2">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-0 pb-0">
              &nbsp;
            </div>

            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right pa-0"
            >
              <v-btn class="light-blue darken-1 white--text" @click="getItems">
                consultar
                <v-icon dark> &nbsp; mdi-magnify </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="array_table"
          item-key="_id"
          class="my-5"
        >
          <template v-slot:top>
            <v-toolbar flat class="d-flex justify-content-end">
              <v-divider v-if="false" class="mx-4" inset vertical></v-divider>
              <v-btn color="green darken-2" dark @click="create_file('excel')">
                Excel &nbsp;
                <v-icon dark> mdi-file-excel </v-icon>
              </v-btn>
            </v-toolbar>
          </template>
        </v-data-table>
        <div class="filtro-botones">
        <button 
          @click="periodoSeleccionado = 3; obtenerHistorialAlmacenamiento()" 
          :class="{ activo: periodoSeleccionado === 3 }">
          Últimos 3 meses
        </button>
        
        <button 
          @click="periodoSeleccionado = 6; obtenerHistorialAlmacenamiento()" 
          :class="{ activo: periodoSeleccionado === 6 }">
          Últimos 6 meses
        </button>
        
        <button 
          @click="periodoSeleccionado = 12; obtenerHistorialAlmacenamiento()" 
          :class="{ activo: periodoSeleccionado === 12 }">
          Últimos 12 meses
        </button>

        <button 
          @click="periodoSeleccionado = mesMasAntiguo; obtenerHistorialAlmacenamiento()" 
          :class="{ activo: periodoSeleccionado === mesMasAntiguo }">
          Historico
        </button>
      </div>

        <!-- Tabla de informacion Almacenamiento -->
        <table class="tabla-almacenamiento">
          <thead>
            <tr>
              <th>Mes</th>
              <th>Almacenamiento Utilizado (GB)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dataSize" :key="index">
              <td>{{ item.mes }}</td>
              <td>{{ item.size.toFixed(2) }} GB</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td><strong>Almacenamiento contratado</strong></td>
              <td><strong>{{ almContratado }} GB</strong></td>
            </tr>
            <tr>
              <td><strong>Promedio Uso</strong></td>
              <td><strong>{{ promedioAlmacenamiento }} GB</strong></td>
            </tr>
            <tr>
              <td><strong>Consumo del Periodo</strong></td>
              <td><strong>{{ consumoPeriodo }} GB</strong></td>
            </tr>
            <tr>
              <td><strong>Almacenamiento disponible</strong></td>
              <td><strong>{{ almDisponible }} GB</strong></td>
            </tr>
          </tfoot>
        </table>

        <!-- Grafico Almacenamiento -->
        <v-card class="mt-5">
          <v-card-title>Gráfico de Almacenamiento</v-card-title>
          <v-card-text>
            <BarChart ref="chart" :chart-data="chartData" :options="chartOptions" style="height: 300px;" />
          </v-card-text>
        </v-card><br><br>

        <!-- Proyeccion de almacenamiento -->
        <div class="projection-container"> 
          <h2 class="projection-title">Proyección de Almacenamiento</h2>

          <div class="projection-div">
            <label class="projection-label" for="meses">¿Cuantos meses deseas proyectar?</label>
            <input class="projection-input" @keyup.enter="calcularProyeccion" v-model.number="mesesProyeccion" type="number" min="1" id="meses" />
            <label class="projection-label" for="mesesAnalisis">Meses a analizar:</label>
            <input class="projection-input" @keyup.enter="calcularProyeccion" type="number" v-model="mesesAnalisis" min="2" max="100" >
          </div>
          <label class="projection-checkbox">
            <input type="checkbox" v-model="usarTodoElHistorial" />
            Consultar todo el historial
          </label>
          
          <button class="projection-button" @click="calcularProyeccion">Calcular Proyección</button>

          <h3>Proyección de Consumo</h3>
          <table class="projection-table">
            <tr>
              <th>Mes</th>
              <th>Proyección de Almacenamiento (GB)</th>
            </tr>
            <tr v-for="item in futureProjections" :key="item.month">
              <td>{{ item.month }}</td>
              <td>{{ item.projectedSize }} GB</td>
            </tr>
          </table>

          <h3>Resultado</h3>
          <p class="projection-result">
            <strong>Almacenamiento proyectado:</strong> 
            {{ almacenamientoTotalProyectado.toFixed(2) }} GB   
          </p>
          <p v-if="almacenamientoTotalProyectado > almContratado" style="color: red;">
            🚨 Debes contratar almacenamiento adicional: {{ (almacenamientoTotalProyectado - almContratado).toFixed(2) }} GB
          </p>
          <p v-else class="projection-alert projection-alert-success">
            ✅ No se necesita contratar más almacenamiento
          </p>
        </div>

      </v-card-text>
    </v-card>
    <ModalForm
      :visible="loader.estado"
      @close="loader.estado = false"
      :message="loader.message"
    />
    

  </v-container>
</template>

<script>
import _ from "lodash";
import ModalForm from "../modal/ModalForm.vue";
import BarChart from './BarChart.js';

export default {
  components: {
    ModalForm,
    BarChart,
  },
  data() {
    return {
      mesesAnalisis: 12,
      usarTodoElHistorial: false,
      mesesProyeccion: 12,
      arrayData: [],
      almContratadoArray: [],
      futureProjections: [],
      equation: "",
      almacenamientoTotalProyectado: 0,
      dataSize: [],
      mesMasAntiguo: '',
      periodoSeleccionado: 6,
      almContratado: null,
      almDisponible: null,
      promedioAlmacenamiento: null,
      consumoPeriodo: null,
      MesReporte: '',
      origin: true,
      loader: {
        estado: false,
        message: "",
      },
      date_range: {
        estado: false,
        value: null,
      },
      headers: [
        { text: "Rango de fechas", value: "range" },
        { text: "Espacio en uso", value: "size" },
      ],
      array_table: [],
      data_impresion: {
        header: [
          {
            name: "Rango de fechas",
            pointer: "range",
            filterButton: false,
          },
          {
            name: "Espacio en uso",
            pointer: "size",
            filterButton: false,
          },
        ],
      },
      //Datos del Grafico 
      chartData: {
        labels: [],
        datasets: [], 
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{}],
          yAxes: [],
        },
      },
    };
  },
  watch: {
    origin: function (val) {
      this.date_range.value = null;
    },
  },
  computed: {
    company() {
        return this.$store.state.company
      },
  },
  created: function() {
    this.getItem();
    this.obtenerHistorialAlmacenamiento();
    this.getItems();
  },
  methods: {

    async obtenerHistorialAlmacenamiento() {
      if (!this.item || !this.almContratado) {
          await this.getItem();
      }
      try {
        const fechaFin = new Date().toISOString().split("T")[0];
        const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        this.MesReporte  = meses[new Date(fechaFin).getMonth()];
        const response = await fetch(`/docs/attached-files/history/${this.$store.state.company}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ date_fin: fechaFin }),
        });

        if (!response.ok) {
          throw new Error(`Error en la solicitud: ${response.status}`);
        }

        const data = await response.json();

        // Asegurarse de que los datos sean válidos
        if (!data || !data.data || data.data.length === 0) {
          console.warn("No hay datos disponibles para actualizar el gráfico.");
          return;
        }

        this.arrayData = data.data;

        const mesesOrdenados = this.arrayData.sort((a, b) => {
            return (a._id.year - b._id.year) || (a._id.month - b._id.month);
        });
        this.mesMasAntiguo = mesesOrdenados[0];

        const filteredData = this.arrayData.slice(-this.periodoSeleccionado);

        if (filteredData.length === 0) {
          console.warn("No hay datos para actualizar el gráfico.");
          return;
        }
        
        // Definir etiquetas (meses) y datos de almacenamiento en uso
        this.labels = filteredData.map((item) => `${item._id.year}-${item._id.month}`);
        this.dataSize = filteredData.map((item) => ({
          mes: `${meses[item._id.month - 1]} ${item._id.year}`,
          size: parseFloat((item.totalSize / (1024 * 1024 * 1024)).toFixed(2)), 
        }));
        const sizeValues = this.dataSize.map(item => item.size);

        const dataSizeTotal = this.arrayData.map((item) => 
          `${(parseFloat(item.totalSize) / (1024 * 1024 * 1024)).toFixed(2)}`
        );
        // Calcular el promedio de almacenamiento utilizado
        const totalAlmacenamiento = dataSizeTotal.reduce((acc, val) => acc + parseFloat(val), 0);
        this.promedioAlmacenamiento = (totalAlmacenamiento / dataSizeTotal.length).toFixed(2);

        this.consumoPeriodo = sizeValues.length > 1 
        ? (parseFloat(sizeValues[sizeValues.length - 1]) - parseFloat(sizeValues[sizeValues.length - 2])).toFixed(2) 
        : "0.00";
        
        const ultimoAlmacenamiento = sizeValues.length > 0 
            ? parseFloat(sizeValues[sizeValues.length - 1])  
            : 0;

        this.almDisponible = parseFloat((this.almContratado - ultimoAlmacenamiento).toFixed(2));

        this.almContratadoArray = this.labels.map(() => Math.max(0, this.almContratado));

        const allData = [
          ...this.almContratadoArray,
          ...sizeValues,
        ].filter(value => value !== null && value !== undefined);

        // Calcula el valor mínimo y máximo dinámicamente
        let yAxisMin;
        const minValue = Math.min(...allData);
        if (minValue > 0) {
          yAxisMin = Math.max(0, minValue - (minValue * 0.20));
        } else {
          yAxisMin = minValue;
        }
        const yAxisMax = Math.max(...allData) + 1;
        
        this.chartData = {
          labels: this.labels,
          datasets: [
            {
              label: "Contratado",
              type: "bar",
              yAxisID: 'y-axis-1',
              backgroundColor: this.almContratadoArray.map((value, index) => 
                value <= sizeValues[index] ? "rgba(255, 99, 132, 0.5)" : "rgba(99, 255, 132, 0.5)"
              ),
              borderColor: this.almContratadoArray.map((value, index) => 
                value <= sizeValues[index] ? "rgba(255, 99, 132, 1)" : "rgba(99, 255, 132, 1)"
              ),
              borderWidth: 1,
              order: 3,
              data: this.almContratadoArray,
            },
            {
              label: "Utilizado",
              type: "line",
              yAxisID: 'y-axis-1',
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 2,
              fill: false,
              order: 1,
              data: sizeValues,
            },
            // Dataset de proyección se agregará en calcularProyeccion()
          ],
        };

        // Configura las opciones del gráfico con un solo eje Y
        this.chartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              // Opciones del eje X si es necesario
            }],
            yAxes: [{
              id: 'y-axis-1',
              type: 'linear',
              position: 'left',
              ticks: {
                min: yAxisMin,
                max: yAxisMax,
                beginAtZero: yAxisMin >= 0,
              },
              scaleLabel: {
                display: true,
                labelString: 'Almacenamiento (GB)',
              },
            }],
          },
        };

      } catch (error) {
        console.error("Error obteniendo el historial de almacenamiento:", error);
        return null;
      }
    },

    calcularProyeccion() {
      let data;

      if(this.usarTodoElHistorial){
        data = this.arrayData.map((item, index) => [index + 1, item.totalSize]);
      } else {
        const lastMonthsData = this.arrayData.slice(-this.mesesAnalisis);
        data = lastMonthsData.map((item, index) => [index + 1, item.totalSize]);
      }      

      // Calcular sumatorias
      let sumX = 0, sumY = 0, sumXY = 0, sumX2 = 0;
      const n = data.length;

      data.forEach(([x, y]) => {
        sumX += x;
        sumY += y;
        sumXY += x * y;
        sumX2 += x * x;
      });

      // Calcular la pendiente y la intersección
      const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
      const intercept = (sumY - slope * sumX) / n;

      // Guardar la ecuación
      this.equation = `Y = ${intercept.toFixed(3)} + ${slope.toFixed(3)} * X`;

      // Proyectar los meses ingresados
      this.futureProjections = [];
      const lastMonthIndex = data.length;

      // Última fecha registrada en la data
      let lastItem = this.arrayData[this.arrayData.length - 1];
      let lastYear = lastItem._id.year;  // Asegurar el acceso correcto
      let lastMonth = lastItem._id.month;

      for (let i = lastMonthIndex + 1; i <= lastMonthIndex + this.mesesProyeccion; i++) {
        lastMonth++;
        if (lastMonth > 12) {
          lastMonth = 1;
          lastYear++;
        }

        // Formatear la fecha
        const formattedDate = new Intl.DateTimeFormat("es-ES", { month: "long", year: "numeric" }).format(new Date(lastYear, lastMonth - 1));

        // Calcular el valor proyectado
        const projectedValue = intercept + slope * i;

        this.futureProjections.push({ month: formattedDate, projectedSize: parseFloat((projectedValue / (1024 * 1024 * 1024)).toFixed(2)) });
      }

      const proyecciones = this.futureProjections.map(item => item.projectedSize);
      this.almacenamientoTotalProyectado = intercept + slope * (lastMonthIndex + this.mesesProyeccion); 
      this.almacenamientoTotalProyectado = parseFloat((this.almacenamientoTotalProyectado / (1024 * 1024 * 1024)).toFixed(2));
      
      const ultimoMes = this.labels[this.labels.length - 1];
      const [ultimoAno, ultimoMesNumero] = ultimoMes.split("-").map(Number);
      const mesesFuturos = [];

      for (let i = 1; i <= this.mesesProyeccion; i++) {
        let nuevoMes = ultimoMesNumero + i;
        let nuevoAno = ultimoAno;

        if (nuevoMes > 12) {
          nuevoMes = nuevoMes % 12 || 12;
          nuevoAno++;
        }

        mesesFuturos.push(`${nuevoAno}-${nuevoMes.toString().padStart(2, "0")}`);
      }
      if (!this.chartData || !this.chartData.datasets) {
        console.error("chartData or chartData.datasets is not defined");
        return;
      }
      // Actualiza las etiquetas del gráfico con los meses futuros
      this.chartData.labels = [
        ...this.labels,
        ...mesesFuturos,
      ];

      // Genera los nuevos valores para "Almacenamiento Contratado"
      const nuevosValoresAlmContratado = mesesFuturos.map(() => Math.max(0, this.almContratado));

      // Extiende almContratadoArray
      this.almContratadoArray = [
        ...this.almContratadoArray,
        ...nuevosValoresAlmContratado,
      ];

      // Actualiza el dataset de "Almacenamiento Contratado"
      this.chartData.datasets[0].data = this.almContratadoArray;
      // Calcula la longitud de los datos históricos
      const datosHistoricosLength = this.chartData.datasets[1].data.length;

      // Crea un array con 'null' para los datos históricos
      const datosProyeccion = new Array(datosHistoricosLength).fill(null);

      // Agrega los valores de proyección al final
      datosProyeccion.push(...proyecciones);
          
      // Verifica si el dataset de proyección existe y actualízalo
      let datasetProyeccion = this.chartData.datasets.find(ds => ds.label === "Proyectado");

      debugger
      
      if (!datasetProyeccion) {
        // Si no existe, lo creamos
        datasetProyeccion = {
          label: "Proyectado",
          type: "line",
          yAxisID: 'y-axis-1',
          backgroundColor: this.almContratadoArray.map((value, index) => 
            value <= proyecciones[index] ? "rgba(255, 99, 132, 0.5)" : "rgba(255, 204, 0, 1)"
          ),
          borderColor: this.almContratadoArray.map((value, index) => 
            value <= proyecciones[index] ? "rgba(255, 99, 132, 1)" : "rgba(255, 204, 0, 1)"
          ),
          borderWidth: 2,
          order: 2,
          borderDash: [5, 5],
          fill: false,
          data: datosProyeccion,
        };
        this.chartData.datasets.push(datasetProyeccion);
      } else {
        // Si existe, actualizamos sus datos
        datasetProyeccion.data = datosProyeccion;
      }


      // Añade los datos de proyección al dataset correspondiente
      this.chartData.datasets[2].data = [
        ...this.chartData.datasets[1].data, // Datos de "Espacio en Uso"
        ...proyecciones, // Datos proyectados
      ];

      // Recalcula los límites del eje Y
      const allData = [
        ...this.almContratadoArray,
        ...this.chartData.datasets[1].data,
        ...proyecciones, // Incluimos directamente los valores de proyecciones
      ].filter(value => typeof value === 'number');

      const yAxisMin = Math.min(...allData, 0);
      const yAxisMax = Math.max(...allData) + 0.5;

      this.chartOptions.scales.yAxes[0].ticks = {
        min: yAxisMin,
        max: yAxisMax,
        beginAtZero: yAxisMin >= 0,
      };

      this.chartData = { ...this.chartData };
      this.chartOptions = { ...this.chartOptions };

      this.$nextTick(() => {
        const chartInstance = this.$refs.chart.chart || this.$refs.chart.$data._chart;
        if (chartInstance) {
          chartInstance.options = this.chartOptions;
          chartInstance.update();
        } else {
          console.warn("⚠️ No se encontró la instancia de Chart.js en el componente.");
        }
      });

    },

    getItems() {
      if (!this.origin && !this.date_range.value) {
        alert("Por favor seleccionar el rango de fechas.");
        return;
      }

      let date_ini,
        date_fin = null;

      if (!this.origin) {
        let date = this.date_range.value;

        if (date.length == 1) {
          date.push(date[0]);
        }

        [date_ini, date_fin] = date;
      }

      this.loader.message =
        "Por favor espere mientras se consultan los datos..";

      this.loader.estado = true;
      let uri = "/docs/attached-files/" + String(this.$store.state.company);

      this.axios
        .post(uri, {
          date_ini,
          date_fin,
          origin: this.origin,
        })
        .then((res) => {
          let size = 0;

          for (const item of res.data.data) {
            size += item.totalSize;
          }

          this.array_table = [
            {
              range: this.origin
                ? "Desde el origen"
                : `${date_ini} - ${date_fin}`,
              size: this.formatBytes(size),
            },
          ];
          this.loader.estado = false;
        })
        .catch((error) => {
          console.log("Error get data: ", error);
          this.loader.estado = false;
          this.$emit("msj", { text: error.message });
        });
    },
    async getItem() {
        let uri = '/cmps/edit/' + this.$store.state.company;
        try {
            const response = await this.axios.get(uri);  
            this.item = response.data;
            this.almContratado = this.item.cmp_almacenamiento_contratado;
        } catch (error) {
            console.error("Error al obtener los datos:", error);
        }
    },
    async create_file(type = "excel") {
      this.data_impresion.content = _.cloneDeep(this.array_table);

      let datos = {
        tipo: type,
        datos: this.data_impresion,
      };

      let new_impresion = new this.gb.Impresion(datos);

      new_impresion.init(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${Math.ceil(parseFloat(bytes / Math.pow(k, i)))} ${sizes[i]}`;
    },
  },
};
</script>

<style lang="css">
.checkBox-origin label.v-label {
  margin-bottom: 0px !important;
}
.tabla-almacenamiento {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.tabla-almacenamiento th, .tabla-almacenamiento td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.tabla-almacenamiento th {
  background-color: #f4f4f4;
}

.tabla-almacenamiento tfoot td {
  font-weight: bold;
  background-color: #e9ecef;
}

.filtro-botones button {
  padding: 8px 15px;
  margin: 5px;
  border: 1px solid #ccc;
  background: #f8f8f8;
  cursor: pointer;
  transition: 0.3s;
}

.filtro-botones button.activo {
  background: #007bff;
  color: white;
  border-color: #007bff;
}

.projection-container {
  font-family: 'Arial', sans-serif;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: auto;
}

.projection-title {
  color: #2c3e50;
  text-align: center;
  font-size: 22px;
  margin-bottom: 15px;
}

.projection-label {
  font-weight: bold;
  margin-top: 10px;
  display: block;
  color: #34495e;
}
.projection-div {
  display: flex;
  align-items: center;
  gap: 10px; /* Espacio entre el título y el input */
}

.projection-input {
  width: 10%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #bdc3c7;
  border-radius: 5px;
  font-size: 14px;
}

.projection-checkbox {
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #34495e;
}

.projection-button {
  background: #3498db;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  width: auto;
}

.projection-button:hover {
  background: #2980b9;
}

.projection-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.projection-table th, .projection-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.projection-table th {
  background: #4a627f;
  color: white;
}

.projection-result {
  font-size: 16px;
  margin-top: 15px;
}

.projection-alert {
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.projection-alert-danger {
  background: #e74c3c;
  color: white;
}

.projection-alert-success {
  background: #27ae60;
  color: white;
}


</style>